import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';

import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_BY_USERNAME, FOLLOW_USER, SET_USER_AVATAR } from '../ApolloClient/API/user';
import { useParams, useLocation, useHistory, Redirect } from "react-router-dom";

import FableFeed from '../Fable/FableFeed';
import { ColorTheme, RefLink, ThemedPaperLayout } from '../utils/ThemedComponents';
import {NativeShare} from '../utils/ModularComponents';
import Avatar from '../utils/Avatar'
import UserContext from './UserContext';
import UserFeed from './UserFeed';
import { ReactComponent as EditSvg} from '../images/ui/018-quill pen.svg';
import { MenuItem, Select } from '@material-ui/core';
import { subscribe } from 'graphql';
let AVATARS = require('../utils/Avatar/avatars.json');

/**
 * 
 * @param {{avatar: string}} props 
 */
function EditableAvatar (props) {
    const {userData, setUserData} = useContext(UserContext);
    const [isOpen, setIsOpen] = useState(false);
    const [avatar, setAvatar] = useState(props.avatar);
    const [badgeAvatar, setBadgeAvatar] = useState(userData.user.avatar);
    const [redirect, setRedirect] = useState(false);
    const [setUserAvatar, {loading, error}] = useMutation(
        SET_USER_AVATAR
    );
    var history = useHistory();

    const handleSubmit = (event) => {
        setUserAvatar({variables: {id: props.userId, avatar: avatar}})
        .then(
            res => {
                if(res.data?.setUserAvatar) {
                    toast.success("Looking good! 👑", {toastId: 'set-avatar'});
                }
                setBadgeAvatar(res.data.setUserAvatar);
            },
            error => {
                toast.error("Sorry 🐉's in the way\nPlease try again...");
                console.log(error.message);
            }
        )
        .then(
            data => {
                // setRedirect(!redirect);
                setUserData({
                    ...userData,
                    user: {
                        ...userData.user,
                        avatar
                    }
                });
                setIsOpen(!isOpen);
            }
        );
    }

    const handleChange = (event) => {
        setAvatar(event.target.value);
    }

    const handleClick = (event) => {
        if (!isOpen){
            setIsOpen(!isOpen);
        }
        else{
            setIsOpen(!isOpen);
        }
    }

    if (redirect) {
        setRedirect(!redirect);
        console.log("Redirecting to profile");
        return <Redirect push to='/profile'/>;
    }

    return (
        <Box>
            {
                isOpen ?
                <Box>
                    <Select
                        labelId="select-avatar-label"
                        id="select-avatar"
                        value={avatar}
                        label='Select Avatar'
                        onChange={ event => {handleChange(event)}}
                        variant='filled'
                        MenuProps={{style: {maxHeight: '20rem'}}}
                        disabled={loading}
                    >
                        {/* <MenuItem value="Random" style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                            <em>Random</em>
                        </MenuItem> */}
                        {
                            AVATARS.map( avatar => (
                                    <MenuItem value={`./${avatar}`} style={{display: 'flex', justifyContent: 'center'}}>
                                        <Avatar avatarPath={`./${avatar}`}/>
                                    </MenuItem>
                                )
                            )
                        }
                    </Select>
                    <Button onClick={handleSubmit} children="Submit" disabled={loading}/>
                </Box>
                :
                <IconButton 
                    title="Edit Profile"
                    onClick={handleClick}
                >
                    <Badge  anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        badgeContent={<EditSvg height="1.5rem" width="1.5rem" />}
                        overlap="circle"
                        title= 'Edit Profile Picture'
                    >
                    {
                        <Avatar avatarSize='xlarge' avatarPath={badgeAvatar}/>
                    }
                    </Badge>
                </IconButton>
            }
        </Box>
    )
}

/**
 * 
 * @param {{data: {avatar: string, username: string, fables: [{}], followers: {username: string, avatar: string}, following: {username: string, avatar: string}}}} props 
 */
export function UserProfile (props) {
    const {userData, setUserData} = useContext(UserContext);
    const [tabValue, setTabValue] = useState(0);
    const [redirect, setRedirect] = useState(false);

    const location = useLocation();
    var history = useHistory();
    const [followUser, {loading, error}] = useMutation(
        FOLLOW_USER, 
        {
            refetchQueries: ['userByUsername']
        }
    );

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const handleFollowToast = (username, didFollow) => {
        if(didFollow){
            toast.success(`Following user: ${username} 💯`);
        }

        else toast.warn(`Unfollowed user: ${username} ✌️`);
    }

    const handleFollow = (event, username) => {
        if (!userData.isLoggedIn){
            setRedirect(true);
        }

        else{
            followUser({variables: {id: userData.user.id, username: username}}).then(
                res => {
                    if(res.data) {
                        const didFollow = res.data.followUser.length > userData.user.following.length;
                        handleFollowToast(username, didFollow);
                        setUserData({
                            ...userData,
                            user: {
                                ...userData.user,
                                following: res.data.followUser
                            }
                        });
                    }
                }
            )
            .catch(error => {
                toast.error("Sorry 🐉's in the way\nPlease try again...");
                console.log(error.message);
            });
        }
    }

    const Fables = (props) => {
        return (
            <Box>
                <FableFeed fables={props.fables}/>
                {
                    props.fables.length < 1 && location.pathname.match(/\/profile/) ?
                    <RefLink to="/create_story">Create Your First Fable!</RefLink>
                    : null
                }
            </Box>
        )
    }

    const Bookmarks = (props) => {
        return (
            <Box>
                <FableFeed fables={props.fables}/>
                {
                    props.fables.length < 1 && location.pathname.match(/\/profile/) ?
                    <RefLink to="/">Bookmark Your Favorite Fables!</RefLink>
                    : null
                }
            </Box>
        )
    }

    const Followers = (props) => {
        return (
            <Box>
                <UserFeed users={props.users}/>
            </Box>
        )
    }

    const Following = (props) => {
        return (
            <Box>
                <UserFeed users={props.users}/>
            </Box>
        )
    }


    if (redirect) history.push('/signup_login', {from: history.location.pathname});

    return (
        <ThemedPaperLayout
            style={{
                backgroundColor: ColorTheme.bright,
                padding: userData.isMobile ? '1.5rem' : '1.5rem 10rem'
            }}>
            <Box style={{ display: 'flex', justifyContent: 'center'}}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="User Tabs"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab label='Fables' />
                    <Tab label='Bookmarks' />
                    <Tab label='Followers' />
                    {
                        location.pathname.match(/\/profile/) &&
                        <Tab label='Following'/>
                    }
                </Tabs>
            </Box>
            <Box style={{textAlign: 'center', alignSelf: 'center'}}>
                    {
                        location.pathname.match(/\/profile/)
                        ? <EditableAvatar avatar={userData.user.avatar} userId={userData.user.id} />
                        : <Avatar avatarSize='xlarge' avatarPath={props.data.avatar}/>
                    }
                <Box style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <Typography
                        variant={userData.isMobile ? 'h5' : 'h3'}
                        style={{
                            color: ColorTheme.primary,
                            display: 'block',
                            overflow: 'auto'
                        }}>
                        <RefLink to={"/user/" + props.data.username}>
                            @{props.data.username}
                        </RefLink>
                    </Typography>

                    {
                        !location.pathname.match(/\/profile/) && props.data.username != userData.user.username &&
                        <Button
                            id={props.data.username}
                            disabled={loading}
                            children={
                                userData && userData.user && userData.user.following && userData.user.following.filter &&
                                userData.user.following.filter( user => 
                                    user.username === props.data.username
                                ).length > 0 ? 'Unfollow' : 'Follow'
                            }
                            onClick={ event => {handleFollow(event, props.data.username)}}
                        />
                    }
                    <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                        <NativeShare
                            shareData={{
                                title: `${props.data.username} is on Fable Frenzy!`,
                                text: `Add ${props.data.username} today.`,
                                url: `/user/${props.data.username}`
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Box style={{display: 'flex', justifyContent: 'center', overflow: 'auto'}}>
                <Box style={{textAlign: 'center', width: 600, overflow: 'auto', padding: '2rem'}}>
                    {
                        tabValue === 0 && <Fables  fables={props.data.fables}/>
                    }
                    {
                        tabValue === 1 && <Bookmarks  fables={props.data.bookmarks}/>
                    }
                    {
                        tabValue === 2 && <Followers users={props.data.followers} />
                    }
                    {
                        tabValue === 3 && props.data && props.data.following && <Following users={props.data.following} />
                    }
                </Box>
            </Box>
        </ThemedPaperLayout>
    )
}


export default function Profile(props){
    let { username } = useParams();
    const { loading, error, data } = useQuery(GET_USER_BY_USERNAME, {variables: {username}}, {fetchPolicy: "no-cache"});

    if (loading) return <Box style={{padding: '8rem', textAlign: 'center'}}><LinearProgress size={40}/></Box>;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <UserProfile data={data.userByUsername}/>
    )
}