import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { ColorTheme, RefLink } from '../utils/ThemedComponents';
import {NativeShare, CustomInputChips} from '../utils/ModularComponents';
import {AuthorContent} from '../Fable';
import BookMarkSVG from '../images/ui/bookmark.svg';
import { IconWrapper } from '../utils/IconWrapper/IconWrapper';
import { Button } from '@material-ui/core';

/**
 * 
 * @param {{loading: boolean, error: string, fables: [], subheader: string}} props 
 */
export default function FableFeed(props){

    const SubHeader = (
        <ListSubheader>
            <Paper style={{backgroundColor: ColorTheme.primary, textAlign: 'center'}}>
                <Typography variant='h4' style={{ paddingTop: '.5rem', color: ColorTheme.bright}}>
                    {props.subheader}
                </Typography>
            </Paper>
        </ListSubheader>
    )

    const NoFeed = (
        <ListItem key={'no-feed'}>
            <ListItemText>
                <Box style={{justifyContent: 'center' }}>
                    <Typography style={{color: 'inherit', alignItems: 'center', textAlign: 'center'}}>
                            No 📚's yet ...
                    </Typography>
                </Box>
            </ListItemText>
        </ListItem>

    )
    

    const Feed = props.fables.map(({ id, title, authors, tags }) =>
        <ListItem key={id} style={{display: 'block'}}>
            <Card style={{display:'block', backgroundColor: ColorTheme.dark}} >
                <CardHeader
                    style={{color: ColorTheme.tertiaryDark}}
                    title={<RefLink style={{color: ColorTheme.tertiary}} to={`/fable/${id}`}>{title}</RefLink>}
                    subheader={<AuthorContent variant="body1" authors={authors} />}
                    action={
                        <IconButton
                            aria-label='bookmark'
                            children={
                                <IconWrapper
                                    src={BookMarkSVG}
                                    iconSize='small'
                                    className='appIcon'
                                />
                            }
                        />
                    }
                />
                <Divider component="li"/>
                <CardContent style={{paddingBottom: 0}}>
                        <ListItemText >
                            <Typography variant="caption" style={{color: ColorTheme.bright, alignItems: 'center'}}>
                                <Box style={{display: 'flex', paddingRight: ".5rem", paddingTop: 'auto' }}>
                                    Tags:
                                </Box>
                            </Typography>
                            <CustomInputChips isReadOnly defaultValue={tags} />
                        </ListItemText>
                </CardContent>
                    <CardActions disableSpacing style={{display: 'block'}}>
                        <Button>
                            {
                                <NativeShare
                                shareData={{
                                    title: `Fable ${title}`,
                                    text: `Check Out ${title} at fablefrenzy.com`,
                                    url: `/fable/${id}`
                                }}
                            />}
                        </Button>
                    </CardActions>
            </Card>
        </ListItem>
    );

    return (
        <List subheader={<li />}>
            { props.subheader ? SubHeader : null }
            { props.fables && props.fables.length > 0 ? Feed : NoFeed }
        </List>
    );
}