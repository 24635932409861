import React from 'react';
import { Link } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';

export default function Contact(){
    return (
        <div style={{display: 'block', textAlign: 'center'}}>
            <Link href="https://www.linkedin.com/in/brandonhdz">
                <LinkedInIcon style={{color: "#0e76a8"}} fontSize="large"/>
            </Link>
            <Link href="https://instagram.com/officialfablefrenzy">
                <InstagramIcon
                    style={{
                        color: "white",
                        borderRadius: "10px",
                        background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                    }}
                    fontSize="large"
                />
            </Link>
            <Link href="https://twitter.com/brand0hernand0">
                <TwitterIcon style={{color: "#00acee"}} fontSize="large"/>
            </Link>
            <Link href="https://github.com/brandonhdz">
                <GitHubIcon style={{color: "black"}} fontSize="large"/>
            </Link>
            <Link href="mailto:officialfablefrenzy@gmail.com">
                <EmailIcon style={{color: "gray"}} fontSize="large"/>
            </Link>
        </div>
    )
};