import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { Link } from "react-router-dom";

import { IconWrapper } from './IconWrapper/IconWrapper';
import { ColorTheme, ThemedTypography, ThemedLinksNav, RefLink } from './ThemedComponents';
import AvatarMenu from './Avatar/AvatarMenu';
import Logo from '../logo.svg';

const ThemedNavbar = (props) => {
    return(
        <Slide appear={false} direction="down" in={!props.trigger}>
            <AppBar style={{backgroundColor: ColorTheme.dark, flexGrow: 1}}>
                <Toolbar>
                    <IconButton edge="start" title='Home'
                        size='small'
                        color="inherit"
                        aria-label="logo"
                        style={{marginRight: '.5rem'}}
                    >
                        <Link to='/' >
                            <IconWrapper src={Logo} />
                        </Link>
                    </IconButton>
                    <Box style={{display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                        <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <ThemedTypography 
                                variant='h5' style={{color: ColorTheme.bright}}
                                children='Fable Frenzy' to='/' component={RefLink}/>
                        </Box>
                        {!props.isMobile && <ThemedLinksNav />}
                    </Box>
                    <AvatarMenu />
                </Toolbar>
            </AppBar>
        </Slide>
    )
}

/**
 * @param {*} props 
 */
function PeekNavBar (props) {

    const trigger = useScrollTrigger();

    return <ThemedNavbar {...props} trigger={trigger}/>
}

ThemedNavbar.propTypes = {
    isMobile: PropTypes.bool.isRequired,
}

export default PeekNavBar;