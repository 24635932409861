import React, { forwardRef } from "react";
import AvatarMUI from '@material-ui/core/Avatar';
import {IconWrapper} from '../IconWrapper/IconWrapper';
const { PropTypes } = require('prop-types');

const reqSvgs = require.context ( '../../images/profiles', true, /\.svg$/ );
// Hack from the gods of StackOverFlow...
export const avatars = reqSvgs
  .keys ()
  .reduce ( ( images, path ) => {
    images[path] = reqSvgs ( path )
    return images
  }, {} );

/**
 * @param {{src: string, iconSize: string}} props
 */
export const AvatarIcon = forwardRef( (props, ref) => {
    AvatarIcon.propTypes = {
        avatarPath: PropTypes.string.isRequired,
    }
    return (
      <IconWrapper
        {...props}
        src={avatars[props.avatarPath]}
        ref={ref}
      />
    )
});

/**
 * @param {{avatarSize: string, avatarPath: string}} props
 */
export default function Avatar(props) {
  return (
    <AvatarMUI
      iconSize={props.avatarSize}
      component={AvatarIcon}
      {...props}
    />
  )
};