import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_ENPOINT,
  cache: new InMemoryCache(),
  fetchOptions: {
      mode: 'no-cors'
  }
});

export default client;