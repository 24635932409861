import React, { useState, useEffect } from 'react';
import PeekNavBar from './utils/PeekNavBar';
import StoryInterface from './StoryInterface/StoryInterface';
import { SignUp } from './User/SignUpLogin/SignUp';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import About from './About/About';
import { CreateFable, NewFableOverlay } from './Fable/CreateFable';
import Home from './Home/Home';
import { SiteTitle, ThemedMobileNav, SelectAvatar } from './utils/ThemedComponents';
import Profile from './User/Profile';
import User from './User'
import Contact from './Contact'
import {PrivacyPolicy} from './Privacy/PrivacyPolicy';
import Fable from './Fable';

import {SESSION_LOGIN, LOGOUT_USER} from './ApolloClient/API/user';
import { useLazyQuery, useMutation } from '@apollo/client';

import { UserProvider } from './User/UserContext';
// import { RecentFables } from './TestComponents'

export default function App (props) {
  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 1100);
  const [ userData, setUserData ] = useState({
    isLoggedIn: false,
    isLogging: false,
    isMobile,
    profile: {},
    user: {},
  })
  const [sessionLogin, {loading, error, data}] = useLazyQuery(SESSION_LOGIN);
  const [logoutUser] = useMutation(LOGOUT_USER, {fetchPolicy: 'no-cache'});

  useEffect(() => {
    // If not already querying or not called at all and not already logged in:
    if ( !(error || data) && !loading && localStorage && localStorage.sessionID !== undefined && !userData.isLoggedIn){
      setUserData({
        ...userData,
        isLogging: true
      })
      sessionLogin({variables: {sessionID: localStorage.getItem('sessionID')}})
    }
    else if (data && !userData.isLoggedIn){
      setUserData({
        ...userData,
        isLoggedIn: true,
        isLogging: false,
        profile: {
          email: data.sessionLogin.email,
          userName: data.sessionLogin.username,
          id: {google: data.sessionLogin.googleID},
          avatar: data.sessionLogin.avatar,
        },
        user: data.sessionLogin
      })
    }
    // session token does not exist, expired, or invalid
    else if (error){
      toast.error("Sorry 🐉's in the way\nPlease try again...");
      // an error will occur ocassionally with closed lambdas
      console.log(error.message);
      // we don't care if below is successful or not as it may already be deleted
      logoutUser({variables: {sessionID: localStorage.sessionID}})
      localStorage.removeItem('sessionID');

      setUserData({
        ...userData,
        isLogging: false
      });
    }
    
    function handleResize(){
      const mobileCheck = window.innerWidth < 1100
      if (mobileCheck !== isMobile ){
        setIsMobile(mobileCheck)
      }
    }

    window.addEventListener('resize', handleResize);

    return _ => {
      window.removeEventListener('resize', handleResize);
    }

  }, [data, loading, window.innerWidth])

  const userValues = { userData, setUserData };

  return (
    <UserProvider value={userValues}>
      <Router>
        <PeekNavBar isLoggedIn={false} text={SiteTitle} isMobile={isMobile}/>
        <Switch>
          <Route exact path='/create_fable' render={ () => <CreateFable isMobile={isMobile} />}/>
          <Route exact path='/test' render={() => <SelectAvatar />} />
          <Route exact path='/profile' render={ () => <Profile />} />
          <Route exact path='/login' render={ () => <SignUp />} />
          <Route exact path='/signup_login' render={() => <SignUp />} />
          <Route exact path="/demo" component={StoryInterface} />
          <Route path="/fable/:id" component={Fable} sensitive/>
          <Route path="/user/:username" component={User} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/privacy_policy" component={PrivacyPolicy} />
          <Route path='/' className="Home" component={Home} />
        </Switch>
        { !isMobile && <NewFableOverlay isMobile={isMobile}/>}
        { isMobile && <ThemedMobileNav isMobile={isMobile}/> }

      </Router>
      <ToastContainer
          position={isMobile ? "top-center" : "bottom-left"}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={3}
      />
    </UserProvider>
  );
}