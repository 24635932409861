import React, {useContext} from 'react';
import {ThemedPaperLayout, ColorTheme, ThemedPaper, RefLink} from '../utils/ThemedComponents'
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Contact from '../Contact';
import fableSplash from '../fable-bg-720x480.png';
import UserContext from '../User/UserContext';

export default function About() {
    const {userData} = useContext(UserContext);
    let width = "450px";
    let height = "300px";
    if (userData.isMobile) {
        width = "222"; height = "148px";
    }
    return (
        <ThemedPaperLayout>
            <Box style={{overflow: 'auto'}}>
                <Box style={{textAlign: 'center'}}>
                    <img src={fableSplash} alt="fable-bg-720x480.png" width={width} height={height}/>
                </Box>
                <ThemedPaper style={{background: ColorTheme.bright}}>
                    <Typography style={{color: ColorTheme.tertiary}} variant='h5'>Background</Typography>
                    <Typography style={{color: ColorTheme.primary}} variant='body1'>
                        The idea behind the app is that; two people can creatively collaborate
                        on a short story. The catch is that it is turn based with a
                        maximum of 256 characters per entry and up to a maximum of 6 entires between 
                        players (3 entries per user). The user who initiates the game can create a title
                        along with tags which can be used for inspiration. The collaborators have full
                        control in this improv-like short story adventure!
                    </Typography>
                    <Typography style={{color: ColorTheme.tertiary}} variant='h5'>Community</Typography>
                    <Typography style={{color: ColorTheme.primary}} variant='body1'>
                        Upon completion, other users can upvote/downvote your short stories or even share them.
                        Watch your short story climb the weekly leaderboards! Add new friends and start collaborating
                        for the ultimate dark or romantic (or both!) short story extravaganza.
                    </Typography>
                    <Typography style={{color: ColorTheme.tertiary}} variant='h5'>Testing</Typography>
                    <Typography style={{color: ColorTheme.primary}} variant='body1'>
                        Currently a static view of the app is availble as the functionality is still being developed.
                        Please feel free to <Link href="mailto:officialfablefrenzy@gmail.com">contact</Link> me with any feedback or app suggestions!
                        I'd love to hear how you feel about the User Interface/User Experience. If there's anything you
                        wish to see in the future dont hesitate to <Link href="mailto:officialfablefrenzy@gmail.com">reach out</Link>!.
                        The app demo can be experienced <RefLink to='/fables'>here</RefLink>.
                    </Typography>
                    <Typography style={{color: ColorTheme.tertiary}} variant='h5'>Contact</Typography>
                    <Typography style={{color: ColorTheme.primary}} variant='body1'>
                        Like what you see? Connect with me on Social Media:
                    </Typography>
                    <Contact />
                    <br></br>
                    <Typography style={{color: ColorTheme.primaryLight}} variant="caption">
                        <i>
                        This site is still in production, with many features to come.
                        As such this site may not work correctly on all browsers/devices.
                        </i>
                        <i>
                            <RefLink to="/privacy_policy">Privacy Policy</RefLink>
                        </i>

                    </Typography>
                </ThemedPaper>
            </Box>
        </ThemedPaperLayout>
    )
}
