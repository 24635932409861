import React, {useContext, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin, GoogleLogout} from 'react-google-login';
import { ThemedTypography, ColorTheme, ThemedPaper} from '../../utils/ThemedComponents';

import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';

import { Formik, Form, Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';

import { Redirect, useHistory, useLocation } from "react-router-dom";

import { useLazyQuery, useMutation } from '@apollo/client';
import { USERNAME_IS_UNIQUE, GOOGLE_LOGIN, ADD_USER } from '../../ApolloClient/API/user';

import Avatar from '../../utils/Avatar';

import UserContext from '../UserContext';
import { toast } from 'react-toastify';
let AVATARS = require('../../utils/Avatar/avatars.json');

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function SignUp (props) {
    const {userData, setUserData} = useContext(UserContext);
    var location = useLocation();
    var history = useHistory();
    
    var curSignup = location.pathname === '/signup_login';
    const [ isSignup, setIsSignUp ] = useState(curSignup);

    useEffect( () => {
        if (curSignup != isSignup) {
            console.log("curSignup != isSignup");
            setIsSignUp(curSignup)
        }
    }, [isSignup])

    if (userData.isLogging) return <Box style={{padding: '8rem', textAlign: 'center'}}><LinearProgress size={40}/></Box>;

    else if (userData.isLoggedIn === true && location.state?.from !== undefined) {
        return <Redirect to={location.state.from} />
    }
    else if (userData.isLoggedIn === true){
        history.goBack();
    }
    
    return (
        <ThemedPaper
            style={{
                background: ColorTheme.secondary, margin: '.5rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                ...props.style
            }}>
            <Box style={{display: 'flex', flexDirection: 'column'}}>
            <ThemedTypography variant='h5' style={{color: ColorTheme.bright, textAlign: 'center'}}>
                {isSignup ? 'Signup today' : 'Login'} to get started!
            </ThemedTypography>
            <Paper style={{padding: '3rem', background: ColorTheme.dark, maxWidth: '40rem'}}>
                <SignUpForm isNew={isSignup} {...props} />
            </Paper>
            </Box>
        </ThemedPaper>
    )
}

const SubmitUser = (props) => {
    const [checkUsername, { loading, error, data }] = useLazyQuery(USERNAME_IS_UNIQUE);
    const [addUser] = useMutation(ADD_USER);

    const { values, setFieldError, submitForm, setSubmitting, isSubmitting } = useFormikContext();
    const {userData, setUserData} = useContext(UserContext);

    const [prevVal, setPrevVal] = useState(false);

    useEffect(() => {
        if (isSubmitting && !loading){
            if (data && prevVal !== data.usernameIsUnique || prevVal === true) {
                if (!data.usernameIsUnique){
                    setPrevVal(false)
                    setFieldError("username", "Username already taken");
                    setSubmitting(false);
                }
                else {
                    if(prevVal !== true) setPrevVal(true);
                    submitForm();
                }
            }
        }

    }, [isSubmitting, submitForm, loading]);

    const handleGoogleSuccess = (response) => {
        const inputUser = {
            tokenID: response.tokenId,
            username: values.username,
            // avatar: './001-knight.svg',
            authType: "GOOGLE"
        };

        addUser({variables: {user: inputUser}}).then(
            res => {
                if (res.data.addUser){
                    setUserData({
                        isLoggedIn: true,
                        profile: {
                            name: inputUser.username,
                            email: res.data.addUser.email,
                            userName: res.data.addUser.username,
                            id: {google: res.data.addUser.googleID},
                            avatar: res.data.addUser.avatar,
                        },
                        user: res.data.addUser,
                        isMobile: userData.isMobile,
                    });
                    localStorage.setItem("sessionID", res.data.addUser.sessions[res.data.addUser.sessions.length - 1])
                }
                else{
                    setFieldError("username", "Username already taken");
                }
            }
        ).catch(
            error => {
                console.log('error', error)
                alert('Error, please try again...')
            }
        )
        // resolveSuccess();
    }

    const handleGoogleFailure = (response) => {
        console.log(response);
    }

    if (loading) return <LinearProgress />
    if (error) return <p>Error, please try again</p>

    const handleClick = () => {
        console.log('values', values)
        if (!values.username) {
            setFieldError('username', 'Required');
        } else if ( values.username.length < 4 ) {
            setFieldError('username', 'Must be at least 4 characters');
        }
        else {
            setSubmitting(true)
            checkUsername({variables: {username: values.username}})
        }
    }

    return (
        <Box style={props.style}>
            <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText={'Register'}
                onSuccess={handleGoogleSuccess}
                onFailure={handleGoogleFailure}
                onClick={handleClick}
                cookiePolicy={'single_host_origin'}
            />
        </Box>
    )
};

function RegisterForm(props){
    const {userData} = useContext(UserContext);
    const [avatar, setAvatar] = useState('');
    const [open, setOpen] = useState(false);
    const handleSubmit = (values, {setSubmitting} ) => {
        setSubmitting(false);
    }

    const handleChange = (event, props) => {
        props.setFieldValue('avatar', event.target.value);
        setAvatar(event.target.value);
    }

    return(
        <Formik
            initialValues={{
                username: '',
                avatar
            }}
            validate={(values) => {
                console.log('values', values);
                const errors = {};
                if ( values.username.length > 20) {
                    errors.username = 'Must be less than 20 characters';
                }
                else if ( values.username.match(/^.*?(?=[\^#%`~@&$*:()<>?/{|}\\ /]).*$/) ) {
                    errors.username = 'Invalid character or space';
                }

                return errors;
            }}
            onSubmit={handleSubmit}
        >
            { props => (
                <Form
                    style={{
                        width: userData.isMobile ? '10rem' : '30rem',
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 'auto'
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: userData.isMobile ? '5rem' : '15rem',
                        }}
                    >
                        <Field
                            component={TextField}
                            name="username"
                            type="username"
                            label="Username"
                            style={{padding: '.5rem'}}
                        />
                        <FormControl style={{minWidth: '100px'}}>
                            <InputLabel id="select-avatar-label" >Avatar</InputLabel>
                            <Select
                                labelId="select-avatar-label"
                                id="select-avatar"
                                value={avatar}
                                label='Select Avatar'
                                onChange={ event => {handleChange(event, props)}}
                                variant='filled'
                                onClick={() => setOpen(true)}
                                MenuProps={{style: {maxHeight: '20rem'}}}
                            >
                                <MenuItem value="Random" style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                                    <em>Random</em>
                                </MenuItem>
                                {
                                    AVATARS.map( avatar => (
                                            <MenuItem value={`./${avatar}`} style={{display: 'flex', justifyContent: 'center'}}>
                                                <Avatar avatarPath={`./${avatar}`}/>
                                            </MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                        <SubmitUser style={{padding: '1em 0 0'}}/>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}

const SignUpForm = (props) => {
    SignUpForm.propTypes = {
        isNew: PropTypes.bool.isRequired,
    }

    const [isNew, setSignUp] = useState(props.isNew);
    const {userData, setUserData} = useContext(UserContext);
    const [googleLogin, {loading, error, data}] = useLazyQuery(GOOGLE_LOGIN)
    const location = useLocation();
    const history = useHistory();

    // listens for a login
    useEffect( () => {
        if (data?.googleLogin){
            resolveSuccess();
        }
        else if (data && !data.googleLogin) setSignUp(true);
    }, [data?.googleLogin]);

    const handleClick = (event) => {
        setSignUp(!isNew);
    }

    const handleGoogleSuccess = (response) => {
        // If login is successful the useEffect methid listens for a change
        googleLogin({variables: {tokenID: response.tokenId}})
    }

    const handleGoogleFailure = (response) => {
        console.log(response);
        setSignUp(true);
    }

    const resolveSuccess = () => {
        if (data.googleLogin){
            localStorage.setItem('sessionID', data.googleLogin.sessions[data.googleLogin.sessions.length - 1])
            setUserData({
                isLoggedIn: true,
                profile: {
                    email: data.googleLogin.email,
                    userName: data.googleLogin.username,
                    id: {google: data.googleLogin.googleID},
                    avatar: data.googleLogin.avatar,
                },
                user: data.googleLogin,
                isMobile: userData.isMobile,
            });
        }
        else handleGoogleFailure();
        //close parent popper component
        if (props.handleClose) {props.handleClose()};
    }

    if (userData.isLoggedIn && !location.pathname.match(/\/profile/) && !location.pathname.match(/\/signup_login/)) { console.log('redirect to profile'); return <Redirect to='/profile'/>;}

    if (loading) return <LinearProgress />

    return (
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', margin: "auto"}}>
            <Box textAlign='center'>
                <ThemedTypography variant='h5' style={{color: ColorTheme.tertiary}}>
                    {
                        isNew
                        ? "Signup free with Google!"
                        : "Login with Google!"
                    }
                </ThemedTypography>
                {
                    isNew
                    ? <RegisterForm isMobile={userData.isMobile}/>
                    : <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        buttonText={"Login"}
                        onSuccess={handleGoogleSuccess}
                        onFailure={handleGoogleFailure}
                        cookiePolicy={'single_host_origin'}
                    />

                }
            </Box>
            <ThemedTypography variant='caption' style={{color: ColorTheme.bright, padding: '.5rem 0 0'}}>
                <Link title={!isNew ? 'Register' : 'Login'}
                    color='inherit' onClick={handleClick}
                >
                    {isNew ? "Returning User?" : "New User?"}
                </Link>
            </ThemedTypography>
        </Box>
    )
}

export { SignUp, SignUpForm }
