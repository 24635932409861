import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useQuery } from '@apollo/client';
import { RECENT_FABLES } from '../ApolloClient/API/fable';

import FableFeed from '../Fable/FableFeed';
import { ThemedPaperLayout } from '../utils/ThemedComponents';
import { toast } from 'react-toastify';

function RecentFables (props){
    const { loading, error, data } = useQuery(RECENT_FABLES);
    
    if (error) {
        toast.error("Sorry 🐉's in the way\nPlease try again...");
        console.log(error.message);
    }
    if (loading) return <Box style={{overflow: 'hidden', alignContent: 'center', marginTop: '3em'}}> <CircularProgress size={60} /> </Box>

    return <FableFeed fables={data.recentFables} subheader={'Recent Fables'}/>
}

export default function Home (props) {
    return (
        <ThemedPaperLayout >
            <Box style={{
                display: 'flex',
                justifyContent: 'space-around',
                overflow: 'auto',
                textAlign: 'center',
            }}>
                <Box style={{width: 600}}>
                    <RecentFables/>
                </Box>
            </Box>
        </ThemedPaperLayout>
    )
}