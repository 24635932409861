import { createContext } from 'react';

const UserContext = createContext(null);
const UserProvider = UserContext.Provider;

/**
 * See App.js for use case. Please see the schema below:
 * 
 * UserContext = {
 *      isLoggedIn: false,
 *      isLogging: false,
 *      isMobile: ,
 *      profile: {basic user data},
 *      user: {full object returned from GraphQL},
 * }
 */

 export default UserContext;
 export {UserProvider};