import { gql } from '@apollo/client';

export const RECENT_FABLES = gql`
    {
        recentFables{
            id
            title
            authors{
                username
                avatar
            }
            tags
            isComplete
        }
    }
`;

export const GET_FABLE = gql`
    query fable($id: ID!){
        fable(id: $id){
            id
            title
            authors{
                username
                avatar
            }
            nextUser
            tags
            entries{
                user{
                    username
                }
                text
            }
            isComplete
        }
    }
`;

export const CREATE_FABLE = gql`
    mutation addFable($fable: NewFable!){
        addFable(fable: $fable){
            id
            title
            authors{
                username
                avatar
            }
            nextUser
            tags
            entries{
                user{
                    username
                }
                text
            }
            isComplete
        }
    }
`;

export const ADD_FABLE_ENTRY = gql`
    mutation addFableEntry($fableEntry: NewEntry!, $id: ID!){
        addFableEntry(fableEntry: $fableEntry, id: $id){
            id
        }
    }
`;