import React, {useContext, useEffect} from 'react';

import { Redirect, useLocation } from 'react-router';

import UserContext from '../UserContext';
import {UserProfile} from '../';
import '../../index.css';

export default function Profile (props) {
    const location  = useLocation();
    const {userData} = useContext(UserContext)

    if (!userData.isLoggedIn && !userData.isLogging && !location.pathname.match(/\/signup_login/)){
        return <Redirect to='/signup_login' />
    }

    return <UserProfile data={userData.user}/>
}