import { gql } from '@apollo/client';

/**
 * QUERIES:
 */

export const GET_USER_BY_USERNAME = gql`
    query userByUsername($username: String!){
        userByUsername(username: $username){
            username
            avatar
            followers{
                username
                avatar
            }
            fables{
                id
                title
                authors{
                    username
                    avatar
                }
                tags
                votes{
                    value
                }
            }
            bookmarks{
                title
                authors{
                    username
                    avatar
                }
                tags
                votes{
                    value
                }  
            }
        }
    }
`;

export const GOOGLE_LOGIN = gql`
    query googleLogin($tokenID: String!){
        googleLogin(tokenID: $tokenID){
            id
            sessions
            googleID
            username
            email
            avatar
            followers{
                username
                avatar
            }
            following{
                username
                avatar
            }
            fables{
                id
                title
                authors{
                    username
                    avatar
                }
                tags
                votes{
                    value
                }
            }
            bookmarks{
                title
                authors{
                    username
                    avatar
                }
                tags
                votes{
                    value
                }  
            }
        }
    }
`;

export const SESSION_LOGIN = gql`
    query sessionLogin($sessionID: String!){
        sessionLogin(sessionID: $sessionID){
            id
            sessions
            googleID
            username
            email
            avatar
            followers{
                username
                avatar
            }
            following{
                username
                avatar
            }
            fables{
                id
                title
                authors{
                    username
                    avatar
                }
                tags
                votes{
                    value
                }
            }
            bookmarks{
                title
                authors{
                    username
                    avatar
                }
                tags
                votes{
                    value
                }  
            }
        }
    }
`;

export const USERNAME_IS_UNIQUE = gql`
    query usernameIsUnique($username: String!){
        usernameIsUnique(username: $username)
    }
`;

/**
 * MUTATIONS:
 */

/**
 * @param {
 *  {
 *      tokenID: string,
 *      username: string,
 *      avatar: string?,
 *      authType: string
 *  }
 * } user
 */
export const ADD_USER = gql`
    mutation addUser($user: NewUser!){
        addUser(user: $user){
            id
            sessions
            googleID
            username
            email
            avatar
            followers{
                username
                avatar
            }
            following{
                username
                avatar
            }
            fables{
                id
                title
                authors{
                    username
                    avatar
                }
                tags
                votes{
                    value
                }
            }
            bookmarks{
                title
                authors{
                    username
                    avatar
                }
                tags
                votes{
                    value
                }  
            }
        }
    }
`;

export const LOGOUT_USER = gql`
    mutation logoutUser($sessionID: String!){
        logoutUser(sessionID: $sessionID)
    }
`;

export const FOLLOW_USER = gql`
    mutation followUser($id: ID!, $username: String!){
        followUser(id: $id, username: $username){
            username,
            avatar
        }
    }
`
export const SET_USER_AVATAR = gql`
    mutation setUserAvatar($id: ID!, $avatar: String!){
        setUserAvatar(id: $id, avatar: $avatar)
    }
`