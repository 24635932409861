import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Avatar from '../utils/Avatar';
import {ThemedTypography, RefLink} from '../utils/ThemedComponents'


/**
 * 
 * @param {{loading: boolean, error: string, users: [{username: string, avatar: string}]}} props 
 */
export default function UserFeed (props){
    const NoFeed = <Typography>No 🧑's Yet...</Typography>;

    const User = (props) => (
        <Box>
            <RefLink style={{color: 'inherit'}} to={`/user/${props.username}`}>
                <i>@{props.username}</i>
            </RefLink>
            <Avatar avatarSize={'small'} avatarPath={props.avatar} />
        </Box>
    )

    const UserFeed = props.users.map(({ username, avatar }) => 
        <User username={username} avatar={avatar} />
    )

    return (
        <ThemedTypography variant='h6' style={{color: 'inherit'}} {...props}>
            { props.users.length < 1 && NoFeed }
            {UserFeed}
        </ThemedTypography>
    )
}