// Temporary Static data
// Up to 256 chars per entry

export default function fetchData() {
    // MAX 64 CHARS???
    const title = 'The Tale of the Three Brothers'

    const text1 = "Hundreds of years ago, the three Peverell brothers were travelling at twilight, and reached a river too dangerous to cross. The three brothers, being trained in the magical arts, simply waved their wands and created a bridge across the river.";
    const text2 = "They were then stopped by Death himself, who felt cheated that they had gotten across the river, as most travellers drowned in it. Death, a cunning liar, then pretended to congratulate them on being clever enough to evade him, and offered each of them";
    const text3 = "a powerful magical item. The first brother, Antioch Peverell, wished to have the most powerful wand out of his combative personality; Death broke a branch off a nearby elder tree and created for him the Elder Wand, a wand more powerful than any other in";
    const text4 = "existence. The second brother, Cadmus Peverell, out of arrogance, wanted to humiliate Death even further, and wished to have the power to bring loved ones from the grave; Death then took a stone from the riverbed and created for him the Resurrection Stone.";

    const usr1 = 'rWeasely555';
    const usr2 = 'hPotter123';

    const entries = [
        {text: text1, userName: usr1},
        {text: text2, userName: usr2},
        {text: text3, userName: usr1},
        {text: text4, userName: usr2},
    ];

    const votes = 135;
    const categories = ['Magic', 'Mystery', 'Dark', 'Fantasy', 'Fiction'];

    return {title, authors: [usr1, usr2], entries, votes, categories};
}
