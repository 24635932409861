import React, {useContext, useState, useRef, useEffect} from 'react';
import AvatarMUI from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';

import { RefLink, ColorTheme } from '../ThemedComponents';
import { ReactComponent as DropMenu } from '../../images/ui/drop-menu.svg'
import Avatar, { AvatarIcon } from '.';
import UserContext from '../../User/UserContext';
import SignUpLoginDialogue from '../../User/SignUpLogin/SignUpLoginDialogue';

import {useMutation} from '@apollo/client';
import {LOGOUT_USER} from '../../ApolloClient/API/user';

function AvatarMenu (props) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'avatarMenu' })
  const {userData, setUserData} = useContext(UserContext);
  const [ isNew, setIsNew ] = useState(false);
  const dialogRef = useRef(null);
  const [logoutUser, {loading, data, error}] = useMutation(LOGOUT_USER, {fetchPolicy: 'no-cache'});

  useEffect( () => {
    if (dialogRef.current && dialogRef.current.state.isOpen && userData.isLoggedIn){
      dialogRef.current.state.toggleOpen();
    }

    // if (userData.setLogin){
    //   dialogRef.current.isOpen=true;
    // }
  }, [userData.isLoggedIn, dialogRef]);

  const logout = (event) => {
    logoutUser({variables: {sessionID: localStorage.sessionID}}).then(
      res => {
        console.log('localStorage.sessionID', localStorage.sessionID);
        if (res.data.logoutUser){
          localStorage.removeItem('sessionID')
        }
        else console.log('error', error);
      }
    );

    popupState.close();
    setUserData({
      ...userData,
      isLoggedIn: false,
      profile: {},
      user: {}      
    })
  }

  const handleClose = (event) => {
    var curNew = false;
    if (event.target.value === 1) {
      curNew = true;
    }
    setIsNew(curNew)
    popupState.close();
    dialogRef.current.state.setIsNew(curNew);
    dialogRef.current.state.toggleOpen();
  }

  if (loading) return (
    <div>
      Logging out...
    </div>
  )
  if (userData.isLogging) return <CircularProgress />
  
  return (
    <div>
        <IconButton size='small' {...bindTrigger(popupState)}>
            {
              userData.profile.avatar ?
              <Avatar fontSize='small' avatarSize={'medium'} avatarPath={userData.user.avatar} />
              :
              <AvatarMUI fontSize='small' />
            }
            <DropMenu style={{height: '.5rem', width: '.5rem', fill: ColorTheme.bright}} />
        </IconButton>

      { userData.isLoggedIn &&
        <Menu
          {...bindMenu(popupState)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem onClick={popupState.close} style={{color: ColorTheme.dark}} component={RefLink} children='Profile' to='/profile' />
          <MenuItem onClick={logout} style={{color: ColorTheme.dark}} component={RefLink} children='Sign Out' to='/' />
        </Menu>
      }

      { !userData.isLoggedIn &&
        <Menu
          {...bindMenu(popupState)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >

          <MenuItem value={0} onClick={handleClose} style={{color: ColorTheme.dark}} children='Login' />
          <MenuItem value={1} onClick={handleClose} style={{color: ColorTheme.dark}} children='Signup' />
        </Menu>
      }
      <SignUpLoginDialogue ref={dialogRef} isOpen={false} isNew={isNew}/>
    </div>
  )
}

export default AvatarMenu;
