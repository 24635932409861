import React, {useContext, useState, useEffect} from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';

// import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { Formik, Form, Field, useFormikContext, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';

import { toast } from 'react-toastify';

import { ThemedPaperLayout, ThemedPaper } from '../utils/ThemedComponents';
import { CustomInputChips, } from '../utils/ModularComponents';

import { ReactComponent as NewStoryIcon } from '../images/ui/043-add.svg';
import '../index.css';

import { useLazyQuery, useMutation } from '@apollo/client';
import { USERNAME_IS_UNIQUE } from '../ApolloClient/API/user';
import { CREATE_FABLE } from '../ApolloClient/API/fable';

import UserContext from '../User/UserContext';

const SubmitFable = (props) => {
    const {userData} = useContext(UserContext);
    const { values, setFieldError, submitForm, setSubmitting, isSubmitting, errors } = useFormikContext();

    const handleError = (error) => {
        setSubmitting(false);
        console.log(error.message);
        toast.error("Sorry 🐉's in the way\nPlease try again...", {toastId: "dragons-in-the-way"});
    }

    const handleOnCompleted = (data) => {
        if (data.usernameIsUnique){
            setFieldError("friend", "User not found...");
            setSubmitting(false);
            toast.error("User not found 🚷");
        }
        //user found
        else {
            // setSubmit is set to false in handleSubmit
            submitForm()
            .then(
                data => {
                    if(data){
                        toast.success('Fable Created! 📜', {toastId: 'fable-created'});
                    }
                }
            )
            .catch( 
                err => {
                    toast.error("Sorry 🐉's in the way\nPlease try again...", {toastId: 'dragons-in-the-way'});
                    console.log(err.message)
                }
            );
        }
    }

    const [checkUsername, { loading, error, data, }] = useLazyQuery(USERNAME_IS_UNIQUE, {
        fetchPolicy: 'no-cache',
        onCompleted: handleOnCompleted,
        onError: handleError
    });

    if (loading || isSubmitting) return <LinearProgress />

    const handleClick = () => {
        if (!userData.isLoggedIn){
            toast.error('Who goes there!? 🧙')
        }
        else if (!values.title || values.title == ''){
            toast.error('Title is required 🚧');
        }
        else if (!values.friend || values.friend == ''){
            toast.error('Username is required 🚧');
        }
        else{
            setSubmitting(true);
            checkUsername({variables: {username: values.friend}});                
        }
    }

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting || errors.title || errors.friend}
            onClick={handleClick}
            children={'Submit'}
        />
    )
}


const CreateFable = (props) => {
    const {userData, setUserData} = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);

    const [createFable, {data}] = useMutation(CREATE_FABLE, {
        onError: error => {
            toast.error(`${error.message}...`, {toastId: "cannot-add-self"});
            console.log(error.message);
        },
        onCompleted: data => {
            setRedirect(true);
        },
        refetchQueries: ['sessionLogin']
    });

    const [chips, setChips] = useState(['Mythical', 'Fairytale']);
    // use or remove... CustomInputChips is set to prevent more than 5 tags
    const [chipError, setChipError] = useState(false);
    
    const onBeforeAdd = (chip) => {
        // if (chipError){
        //     if (chip.length < 12){
        //         setChipError(false);
        //         return true
        //     } 
        // }
        // else {
            if (chip.length > 12){
                return false
            } else if (chips.length > 4) {
                return false;
            } else {
                return true
            }
        // }
    }

    const onAddChip = (chip) => {
        const newChips = chips;
        newChips.push(chip)
        setChips(newChips);
    }

    const onDeleteChip = (chip) => {
        const newChips = chips;
        newChips.pop(chip)
        setChips(newChips);
    }

    const handleSubmit = (values, {setSubmitting} ) => {
        createFable({
            variables: {
                fable: {
                    title: values.title,
                    authors: [userData.user.username, values.friend],
                    tags: values.tags
                }
            }
        })
        .then(
            data => {
            }
        )
        .catch(
            err => {
                toast.error("Sorry 🐉's in the way\nPlease try again...", {toastId: "dragons-in-the-way"});
                console.log(err.message);
            }
        )
        .finally(
            () => {
                setSubmitting(false);
            }
        )
    }

    if (redirect && data.addFable.id){
        return <Redirect push to={`/fable/${data.addFable.id}`} />
    }

    return (
        <ThemedPaperLayout>
            <ThemedPaper style={{alignSelf: 'center', margin: 'auto 0'}}>
                <Formik
                    initialValues={{
                        title: '',
                        tags: chips,
                        friend: '',
                    }}
                    validate={
                        values => {
                            const errors = {};
                            if (!values.title) {
                                errors.title = 'Required (can edit later)';
                            } else if ( values.title.length < 4 ) {
                                errors.title = 'Must be at least 4 characters';
                            } else if ( values.title.length > 56 ) {
                                errors.title = 'Must be less than 56 characters';
                            }
                            if (!values.friend) {
                                errors.friend = 'Required';
                            }

                            return errors;
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form
                            style={{
                                width: props.isMobile ? '15rem' : '30rem',
                                display: 'table'
                            }}
                        >
                            <div style={{display: 'flex', flexDirection: 'column'}} >
                                <Field
                                    component={TextField}
                                    name="title"
                                    type="title"
                                    label="Title"
                                    style={{margin: '.5rem'}}
                                />
                                {/* <FieldArray
                                    name="tags"
                                    render={
                                        arrayHelpers => {
                                            <
                                    }}
                                /> */}

                                <CustomInputChips
                                    error={chipError}
                                    disabled={isSubmitting}
                                    value={chips}
                                    onAdd={onAddChip}
                                    onBeforeAdd={onBeforeAdd}
                                    onDelete={onDeleteChip}
                                    // component={CustomInputChips}
                                    name="tags"
                                    type="tags"
                                    label="Tags (5 max)"
                                    style={{margin: '.5rem'}}
                                />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Field
                                    component={TextField}
                                    name="friend"
                                    type="friend"
                                    label="Add Friend"
                                    style={{margin: '.5rem'}}
                                />
                            </div>
                            <SubmitFable />
                        </Form>
                    )}
                </Formik>
            </ThemedPaper>
        </ThemedPaperLayout>
    )
}

const NewFableOverlay = (props) => {
    const {userData} = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const location  = useLocation();
    var history = useHistory();

    useEffect( () => {
        if (!userData.isLoggedIn && location.pathname.match(/\/create_fable/)){
            setRedirect(true);
        }
    }, [userData.isLoggedIn])

    // FAB = Floating Action Button
    const FabDesktop = {
        position: 'fixed',
        bottom: '2rem',
        right: '2.75rem',
    }
      
    const FabMobile = {
        position: 'fixed',
        bottom: '4rem',
        right: '1.25rem',  
    }

    const handleClick = (event) => {
        setRedirect(!redirect);
    }

    useEffect( () => {
        // Once the component has redirected reset the state
        // Since this component is 'global'
        if (redirect == true) {
            setRedirect(!redirect)
        }
    }, [redirect]);

    if (redirect == true) {
        if (!userData.isLoggedIn){
            return <Redirect push to={{
                pathname: '/signup_login',
                state: { from: '/create_fable'}
            }}/>
        }
        else {
            return (
                <Redirect push to='/create_fable'/>
            )
        }
    }
    
    return (
        <Tooltip 
            title="New Fable"
            arrow
            TransitionComponent={Zoom}
        >
            <Fab style={props.isMobile ? FabMobile : FabDesktop}
                aria-label="New Fable"
                onClick={handleClick}
            >
                <NewStoryIcon className='appIcon'/>
            </Fab>
        </Tooltip>
    )
}

export {CreateFable, NewFableOverlay}