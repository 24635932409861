import React, { forwardRef, useState, useContext, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ShareIcon from '@material-ui/icons/Share';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ChipInput from 'material-ui-chip-input';

import {toast} from 'react-toastify';

import { ColorTheme, SiteDomain } from './ThemedComponents';
import BookMarkSVG from '../images/ui/bookmark.svg';
import CloseSVG from '../images/ui/040-close.svg';
import { IconWrapper } from './IconWrapper/IconWrapper';
import MinusSVG from '../images/ui/minus-1.svg';
import OptionsSVG from '../images/ui/028-option.svg';
import PlusSVG from '../images/ui/plus-1.svg';
import UserContext from '../User/UserContext';
import '../index.css';

/**
 * 
 * Components that dynamically change the UI 
 */

function CreateButton (props) {
    return (
        <IconButton onClick={props.handleClick}
                size='medium'
                type={props.typeSubmit ? 'submit' : ''}
                variant='outlined'
                style={{backgroundColor: ColorTheme.bright, margin: '0 0 0 0.5rem',}}>
            <CreateIcon style={{color: ColorTheme.primary, backgroundColor: ColorTheme.bright}}/>
        </IconButton>
    )
}

class SubmitForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            textInput: '',
            handleChange: this.handleChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this),
        }
    }

    handleChange = (event) => {
        this.setState( {...this.state, textInput: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const text = this.state.textInput;
        if (text !== '' && text !== undefined) {
            this.setState({...this.state, textInput: ''});
            this.props.handleSubmit(event, text);
        }
    }

    render () {
        return (
            <form onSubmit={this.state.handleSubmit} style={{display: 'flex', width: "100%", justifyContent: 'space-between', margin: ' 0.5rem 0 0 0 ', alignItems: 'center'}}>
                <TextField id="text-input"
                    label={this.props.label}
                    variant='outlined'
                    size='small'
                    multiline
                    fullWidth={true}
                    rowsMax={3}
                    value={this.state.textInput}
                    onChange={this.state.handleChange}
                    style={{margin: "auto"}}
                    inputProps ={{maxLength: 256,}}
                    InputProps = {{
                        endAdornment: <InputAdornment position="end">{this.state.textInput.length + "/256"}</InputAdornment>
                    }}
                />
                <CreateButton typeSubmit={true} handleClick={this.state.handleSubmit} />
            </form>        
        )
    }
}

const VoteActions = (props) => {
    const [ votes, setVotes ] = useState( props.votes );

    useEffect(() => {
        setVotes(props.votes);
    }, [props.votes])

    const handleUpvote = () => {
        setVotes(votes + 1)
    }

    const handleDownvote = () => {
        setVotes(votes - 1)
    }

    return (
        <ButtonGroup aria-label='upvote downvote actions'>
            <IconButton 
                variant='outlined'
                size='small'
                aria-label='upvote'
                children={<IconWrapper src={MinusSVG} iconSize='small' />}
                onClick={handleDownvote}
            />
            <Typography variant='subtitle1' style={{color: ColorTheme.bright, textAlign: 'center', marginTop: 'auto'}}>
                { votes }
            </Typography>
            <IconButton
                variant='outlined'
                size='small'
                aria-label='downvote'
                children={<IconWrapper src={PlusSVG} iconSize='small' />}
                onClick={handleUpvote}
            />
        </ButtonGroup>
    )
}

/**
 * 
 * @param {{chips: [string], shareData: {title: string, text: string, url: String}}} props 
 */
const MoreOptions = (props) => {
    const [ isExpanded, setExpand ] = useState(false);

    // Aligns Chips nicely in Desktop View
    const alignChipsEnd = {width: '100%', alignItems: 'end',};
    const toggleOptions = () => {
        setExpand(!isExpanded)
    }

    const SetIcon = () => {
        return (
            isExpanded ? 
            <IconWrapper src={CloseSVG} iconSize='small' style={{fill: ColorTheme.primary}} />
            :
            <IconWrapper src={OptionsSVG} iconSize='small' style={{fill: ColorTheme.primary}} />
        )
    }

    const ButtonOptions = (props) => (
        <>
            <Button
                aria-label='bookmark'
                children={
                    <IconWrapper
                        src={BookMarkSVG}
                        iconSize='small' 
                        className='appIcon'
                    />
                }
            />
            <Button
                aria-label='bookmark'
                children={
                    <NativeShare
                        shareData={props.shareData}
                    />
                }
            />

        </>
    )

    return (
        <Box className='moreOptions' style={{ alignContent: 'end', alignItems: 'end', textAlign: 'end' }}>
            { 
                isExpanded ?
                <ButtonOptions shareData={props.shareData} />
                : null
            }
            <IconButton variant='outlined' size='small'
                aria-label='options'
                children={<SetIcon />}
                onClick={toggleOptions}
                style={{alignSelf: 'end', marginLeft: 'auto'}}
            />
            { isExpanded ? <CustomInputChips isReadOnly style={alignChipsEnd} defaultValue={props.chips}/> : null}
        </Box>
    )
}

/**
 * @param {{defaultValue: [string], isReadOnly:boolean, value: [string]}} props
 * @param {} ref
 */
const CustomInputChips = forwardRef( (props, ref) => {
    // Static chips: 
    const {userData} = useContext(UserContext);

    const customChip = ({
        value,
        text,
        chip,
        isFocused,
        disabled,
        handleClick,
        handleDelete,
        className,
      },
      key) => (
        <Chip
            key={key}
            className={className}
            disabled={disabled}
            style={{
                // We could disable below
                // pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
                backgroundColor: isFocused ? ColorTheme.tertiaryDark : ColorTheme.tertiary,
            }}
            onClick={handleClick}
            onDelete={ props.isReadOnly || props.disabled ? undefined : handleDelete}
            label={value}
            size={userData.isMobile ? 'small' : 'medium'}
        />
      )

    return (
        <ChipInput
            error={!!props.error}
            // If data exists return values
            defaultValue={props.isReadOnly ? props.defaultValue : undefined}
            newChipKeys={[`Space`, ` `, `,`]}
            value={props.isReadOnly ? undefined : props.value}
            style={props.style}
            onAdd={props.onAdd}
            onBeforeAdd={props.onBeforeAdd}
            onDelete={props.onDelete}
            chipRenderer={customChip}
            disableUnderline={props.isReadOnly}
            disabled={props.isReadOnly || props.disabled}
            // Love this hack, only display when creating new chips...
            InputProps={{ style: {display: props.isReadOnly ? 'none' : 'inline-block', }}}
            label={props.label}
            ref={ref}
        />       
    )
});

/**
 * Text to copy
 * @param {string} text 
 */
const copyToClipboard = async(text) => {
    // return await navigator.permissions.query({name: "clipboard-write"})
    // .then( async (result) => {
    //     if (result.state == "granted" || result.state == "prompt") {
    //         console.log('Copying') 
            return navigator.clipboard.writeText(text).then(function() {
                return text
                }, function() {
                return null
                });
//         }
//       });
}

/**
 * 
 * @param {{title: string, text: string, url: string}} shareData 
 */
const retrieveNativeShare = async(shareData) => {
    shareData.url = shareData.url.includes(SiteDomain) ? shareData.url : SiteDomain + shareData.url
    try {
        await navigator.share(shareData)
      } catch(err) {
        let didCopy = await copyToClipboard(shareData.url)
        if(didCopy){
            return true
        }
      }
}

/**
 * 
 * @param {{shareData: {title: string, text: string, url: string}}} props 
 */
const NativeShare = (props) => {
    const [open, setOpen] = useState(false);

    const handleClickAway = () => {
        setOpen(false);
    }

    useEffect( () => {
        if(open){
            setTimeout( () => {setOpen(false)}, 1500)
        }
    }, [open])

    const handleShare = async() => {
        const didCopy = await retrieveNativeShare(props.shareData)
        if(didCopy){
            setOpen(true)
        }
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Tooltip open={open} arrow title="Copied to Clipboard!">
                <ShareIcon onClick={handleShare}/>
            </Tooltip>
        </ClickAwayListener>
    )
}

export { CreateButton, SubmitForm, VoteActions, MoreOptions, CustomInputChips, NativeShare }