import React, {useContext, useState} from 'react';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';

import { useQuery, useMutation } from '@apollo/client';
import { GET_FABLE, ADD_FABLE_ENTRY } from '../ApolloClient/API/fable';
import { useParams } from "react-router-dom";
import { Redirect, useHistory } from 'react-router';

import {toast} from 'react-toastify';

import { SubmitForm, VoteActions, MoreOptions } from '../utils/ModularComponents';
import {ThemedPaperLayout, ColorTheme, ThemedTypography, ThemedPaper, RefLink} from '../utils/ThemedComponents'
import Avatar from '../utils/Avatar';

import UserContext from '../User/UserContext';
import { useEffect } from 'react';

/**
 * 
 * @param {{authors: [{username: string, avatar:string }], variant: string}} props 
 */
export const AuthorContent = (props) => {
    return (
        <ThemedTypography variant='h6' style={{color: ColorTheme.bright}} {...props}>
            Written by:&nbsp;
            <RefLink style={{color: 'inherit'}} to={`/user/${props.authors[0].username}`}>
                <i>@{props.authors[0].username}</i>
            </RefLink>
            <Avatar avatarSize={'small'} avatarPath={props.authors[0].avatar} />
            &nbsp;and&nbsp;
            <RefLink style={{color: 'inherit'}} to={`/user/${props.authors[1].username}`}>
                <i>@{props.authors[1].username}</i>
            </RefLink>
            <Avatar avatarSize={'small'} avatarPath={props.authors[1].avatar} />
        </ThemedTypography>
    )
}

/**
 * 
 * @param {{username: string, text: string, isPrimary: boolean}} props
 */
const SingleEntryCard = (props) => {
    return (
        <ThemedPaper style={{background: ColorTheme.bright}}>
            <ThemedTypography variant='body1' style={{color: ColorTheme.primary}}>{props.text}</ThemedTypography>
            <hr></hr>
            <ThemedTypography variant='subtitle1'
                style={{color: props.isprimary ? ColorTheme.tertiaryDark : ColorTheme.brightDim}}>
                {props.username}
            </ThemedTypography>
        </ThemedPaper>
    )
}

/**
 * 
 * @param { {entries: [{user: {username: string}, text: string}]} } props
 */
const CompiledEntriesCard = (props) => {
    return (
        <ThemedPaper style={{background: ColorTheme.bright}}>
            <ThemedTypography variant='body1'>
                {
                    props.entries.map( (entry, index) => (
                        <Link style={{color: index % 2 === 0 ? ColorTheme.tertiaryDark : ColorTheme.brightDim}}
                            title={entry.user.username} key={index} underline={'none'}
                        >
                            {entry.text + ' '}
                        </Link>
                    ))
                }
                </ThemedTypography>
        </ThemedPaper>
    )
}

export default function Fable (props){
    let { id } = useParams();
    const [showSubmitForm, setShowSubmitForm] = useState(null);
    const onCompleted = (res) => {
        if (res.fable && !res.fable.isComplete){
            setShowSubmitForm(data.fable.authors[data.fable.nextUser].username === userData.user.username);
        }
    }
    const { loading, error, data } = useQuery(GET_FABLE, {variables: {id}, onCompleted});
    const [addFableEntry] = useMutation(
        ADD_FABLE_ENTRY,
        {
            refetchQueries: ['fable']
        }
    );
    const {userData} = useContext(UserContext);
    const [isCompiledView, setCompiledView] = useState(false);
    var history = useHistory();

    function handleViewChange(event) {
        setCompiledView(!isCompiledView)
    }

    function handleSubmit(event, text) {
        event.preventDefault();
        addFableEntry({
            variables: {
                fableEntry: {
                    user: userData.user.username,
                    text
                },
                id
            }
        })
        .then(
            res => {
                if (res.data.addFableEntry){ 
                    setShowSubmitForm(false);
                    toast.success(`Added new entry ➡️💬`)
                }
                else toast.success("Error...");
            }, 
            err => toast.error(err.message)
        )

    }

    useEffect(() => {
        if(showSubmitForm !== null && !loading && data?.fable){
            if (!showSubmitForm){
                toast.info(`Awaiting ${data?.fable?.authors[data?.fable?.nextUser].username}'s entry 💬⬅️`, {toastId: 'awaiting-entry'});
            }
            else{
                toast.info(`You're up! Type up something witty 😉`, {toastId: 'awaiting-entry'});
            }
        }
        
    }, [showSubmitForm, data])

    if (!userData.isLogging && !userData.isLoggedIn){
        return <Redirect to={{pathname: '/signup_login', state: {from: history.location.pathname}}}/>;
    }
  
    if (loading) return <Box style={{padding: '8rem', textAlign: 'center'}}><LinearProgress size={40}/></Box>;
    else if (error) {
        toast.error("Invalid request 😧", {toastId: "invalid-request"});
        return <Redirect to="/"/>
    }
    else if (data?.fable === null){
        toast.error("Fable Not Found 😞", {toastId: "fable-not-found"});
        return <Redirect to="/"/>
    }

    return (
        <ThemedPaperLayout>
            <Box className='storyHeader' style={{display: "flex", flexDirection: 'row', }}>
                <Box className='leftHeader'>
                    <ThemedTypography variant={userData.isMobile ? 'h4': 'h3'} style={{color: ColorTheme.primary}}>
                        {data.fable.title}
                    </ThemedTypography>
                    { userData.isMobile ? null: <AuthorContent authors={data.fable.authors}/> }
                </Box>
                <Box className='rightHeader' style={{marginLeft: 'auto'}}>
                    <MoreOptions
                        chips={data.fable.tags}
                        shareData={{
                            title: `Fable ${data.fable.title}`,
                            text: `Check Out ${data.fable.title} at fablefrenzy.com`,
                            url: `/fable/${data.fable.id}`
                        }}
                    />
                </Box>
            </Box>
            { !userData.isMobile ? null: <AuthorContent authors={data.fable.authors}/> }
            <Box className='storyActions' style={{display: "flex", flexDirection: 'row'}}>
                <Box className='leftActions ' style={{display: 'flex', flexDirection: 'row', marginRight: 'auto', alignItems: "center" }}>
                    <VoteActions votes={123} />
                </Box>
                <Box className='rightActions ' style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', alignItems: 'end'}}>
                    <Box className='toggleAction' style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                        <ThemedTypography variant='body2' style={{color: ColorTheme.bright}}>{"View:"}</ThemedTypography>
                        <Switch
                            checked={isCompiledView}
                            onChange={handleViewChange}
                            name="compiledView"
                            inputProps={{ 'aria-label': 'toggle compiled view' }}
                            style={{color: ColorTheme.primary}}
                        />
                    </Box>
                </Box>
            </Box>
            <Box style={{overflow: 'auto'}}>
                {
                    isCompiledView && data.fable.entries.length > 0 ?
                    <CompiledEntriesCard entries={data.fable.entries}/>
                    :
                    data.fable.entries.map((entry, index) => (
                        <SingleEntryCard key={index} isprimary={index % 2 === 0 ? true : false}
                        text={entry.text} username={entry.user.username} /> ))
                }
            </Box>
            {
                showSubmitForm
                ? <SubmitForm label={"What's next?..."} handleSubmit={handleSubmit}/>
                : null
            }
        </ThemedPaperLayout>
    )
}