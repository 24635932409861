import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';

import { SubmitForm, VoteActions, MoreOptions } from '../utils/ModularComponents';
import { ThemedPaperLayout, ColorTheme, ThemedTypography, ThemedPaper } from '../utils/ThemedComponents';
import fetchData from './StoryInterfaceFetch';
import UserContext from '../User/UserContext';

import '../index.css';

export default class StoryInterface extends React.Component {
    static contextType = UserContext;
    constructor(props, context) {
        super(props, context)
        this.state = {
            story: {
                title: '',
                authors: '',
                entries: [],
                votes: undefined,
                categories: [],
            },
            currentUser: {
                pendingTurn: false,
                userName: '',
            },
            compiledView: false,
            handleViewChange: this.handleViewChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this),
        }
    }

    // getSnapshotBeforeUpdate (prevProps, prevState) {
    //     if (prevState.currentUser.userName !== this.context.userData.profile.username) {
    //         this.setState({ ...this.state.currentUser, currentUser: { ...this.state.currentUser.pendingTurn, userName: this.context.userData.profile.username}})
    //     }
    // }

    componentDidMount () {
            this.setState({
                story: fetchData(),
                currentUser: {
                    userName: this.context.userData.profile.userName
                }
            })
    }

    handleSubmit (event, text) {
        // TODO update pending turn prop
        event.preventDefault();
        // TODO POST to GraphQL
        this.setState({...this.state, story: {...this.state.story,
            ...this.state.story.entries.push({text: text, userName: this.state.story.entries.length % 2 == 0 ? 'rWeasely555' : 'hPotter123'})} });
    }

    handleViewChange (event) {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    }

    AuthorContent () {
        return (
            <ThemedTypography variant='h6' style={{color: ColorTheme.bright}}>
                Written by: {this.state.story.authors[0]} and {this.state.story.authors[1]}
            </ThemedTypography>
        )
    }

    render () {
        // console.log(this.state.story.votes);
        return (
            <ThemedPaperLayout>
                <Box style={{overflow: 'auto'}}>
                <Box className='storyHeader' style={{display: "flex", flexDirection: 'row', }}>
                    <Box className='leftHeader'>
                        <ThemedTypography variant={this.context.userData.isMobile ? 'h4': 'h3'} style={{color: ColorTheme.bright}}>
                            {this.state.story.title}
                        </ThemedTypography>
                        { this.context.userData.isMobile ? null: this.AuthorContent.bind(this)() }
                    </Box>
                    <Box className='rightHeader' style={{marginLeft: 'auto'}}>
                        <MoreOptions chips={this.state.story.categories}/>
                    </Box>
                </Box>
                { this.context.userData.isMobile ? this.AuthorContent.bind(this)() : null }
                <Box className='storyActions' style={{display: "flex", flexDirection: 'row'}}>
                    <Box className='leftActions ' style={{display: 'flex', flexDirection: 'row', marginRight: 'auto', alignItems: "center" }}>
                        <VoteActions votes={this.state.story.votes} />
                    </Box>
                    <Box className='rightActions ' style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', alignItems: 'end'}}>
                        <Box className='toggleAction' style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                            <ThemedTypography variant='body2' style={{color: ColorTheme.bright}}>{"View:"}</ThemedTypography>
                            <Switch
                                checked={this.state.compiledView}
                                onChange={this.state.handleViewChange}
                                name="compiledView"
                                inputProps={{ 'aria-label': 'toggle compiled view' }}
                                style={{color: ColorTheme.primary}}
                            />
                        </Box>
                    </Box>
                </Box>
                {
                    this.state.compiledView ?
                    <CompiledEntriesCard entries={this.state.story.entries}/>
                    :
                    this.state.story.entries.map((entry, index) => (
                        <SingleEntryCard key={index} isprimary={index % 2 === 0 ? true : false}
                        text={entry.text} userName={entry.userName} /> ))
                }
                <SubmitForm label={"What's next?..."} handleSubmit={this.state.handleSubmit}/>
                </Box>
            </ThemedPaperLayout>
        )
    }
}

const SingleEntryCard = (props) => {
    return (
        <ThemedPaper style={{background: ColorTheme.bright}}>
            <ThemedTypography variant='body1' style={{color: ColorTheme.primary}}>{props.text}</ThemedTypography>
            <hr></hr>
            <ThemedTypography variant='subtitle1'
                style={{color: props.isprimary ? ColorTheme.tertiary : ColorTheme.brightDim}}>
                {props.userName}
            </ThemedTypography>
        </ThemedPaper>
    )
}

const CompiledEntriesCard = (props) => {
    return (
        <ThemedPaper style={{background: ColorTheme.bright}}>
            <ThemedTypography variant='body1'>
                {
                    props.entries.map( (entry, index) => (
                        <Link style={{color: index % 2 === 0 ? ColorTheme.primary : ColorTheme.tertiary}}
                            title={entry.userName} key={index} underline={'none'}
                        >
                            {entry.text + ' '}
                        </Link>
                    ))
                }
                </ThemedTypography>
        </ThemedPaper>
    )
}