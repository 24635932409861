import React, {forwardRef, useState, useContext, useEffect} from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Box from '@material-ui/core/Box';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { Link as RouterLink, useLocation } from "react-router-dom";

import { ReactComponent as NewStoryIcon } from '../images/ui/043-add.svg';
import { ReactComponent as DiscoverIcon } from '../images/ui/041-location.svg';
import { ReactComponent as AboutIcon } from '../images/ui/019-information.svg';
import UserContext from '../User/UserContext';
import Avatar from './Avatar';
let AVATARS = require('../utils/Avatar/avatars.json');

const SiteTitle = "Fable Frenzy";
const SiteDomain = `https://fablefrenzy.com`

const ColorTheme = {
    primary: "#286A8C", primaryLight: "#9CB3C9",
    secondary: "#F1A92D", secondaryLight: "#ecb555",
    tertiary: "#E17740", tertiaryLight: "#ff8c52", tertiaryDark: "#ec611b",
    bright: "#F8F9FA", brightDim: "#918298",
    dark: "#3E3D3E", darkDim: "#666666"
}

/**
 * IMPORTANT: note that ...props.style is last
 * to allow properties to be overwritten from HOC's
 * @param {{children, ...TypographyTypeMap}} props
 */
const ThemedTypography = forwardRef( (props, ref) => (
    <Typography ref={ref} className="ThemedTypography" {...props}>
        {props.children}
    </Typography>
));

/**
 * 
 * IMPORTANT: note that ...props.style is last
 * to allow properties to be overwritten from HOC's
 * 
 * @param {*} props 
 */
const ThemedPaper = forwardRef( (props, ref) => (
    <Paper ref={ref} className="ThemedPaper"
        style={{margin: '.5rem 0', padding: '0.5rem', ...props.style}}
        elevation={props.elevation} variant={props.variant}
    >
        {props.children}
    </Paper>
));

/**
 * 
 * ONLY ONE PER PAGE
 * Defaults to take nearly the full initial screen height
 * @param {{style:React.CSSProperties}} props 
 */
const ThemedPaperLayout = forwardRef( (props, ref) => {
    /**
     * Meant for one per page. Serves as 
     * a flex container for all its content
     */

    const {userData} = useContext(UserContext);

    const customStyles = {
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        padding: '1rem',
        margin: '0 .5rem',
        overflow: 'hidden',
        background: ColorTheme.secondary,
        ...props.style
    }

    return (
        <Box style={{
            margin: '.5rem .5rem 0',
            display: 'flex',
            flexWrap: 'column',
            justifyContent: 'space-around',
            overflow: 'auto',
            height: userData.isMobile ? '37rem' : '55rem',
        }}>
            <ThemedPaper ref={ref} style={customStyles} >
                {props.children}
            </ThemedPaper>
        </Box>
    )}
)

/**
 * Designed for desktop view will not render if in mobile view
 * @param {*} props 
 */
const ThemedLinksNav = (props) => {
    const {userData} = useContext(UserContext);
    let location = useLocation();

    return (
        <Box className='themedLinksNav' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...props.style}}>
            <Tabs
                value={location.pathname}
                indicatorColor="primary"
                textColor="primary"
                aria-label="disabled tabs example"
            >
                <Tab label='DEMO' style={{color: ColorTheme.bright}} component={RefLink} value='/demo' to='/demo'/>
                {
                    userData.isLoggedIn ?
                    <Tab label="PROFILE" style={{color: ColorTheme.bright}} component={RefLink} value='/profile' to='/profile'/>
                    :
                    <Tab label="SIGNUP" style={{color: ColorTheme.bright}} component={RefLink} value='/signup_login' to='/signup_login'/>
                }
                <Tab label="ABOUT" style={{color: ColorTheme.bright}} component={RefLink} value='/about' to='/about'/>
            </Tabs>
        </Box>
    );
}

/**
 * For Mobile view only!!
 * @param {*} props 
 */
const ThemedMobileNav = (props) => {
    let location = useLocation();

    return (
      <BottomNavigation
        value={location.pathname}
        showLabels
        style={{ width: '100%', position: 'fixed', bottom: 0}}
      >
        <BottomNavigationAction label="New Story" value='/create_fable'
            icon={<NewStoryIcon style={{height: '1.5rem', width: '1.5rem'}}/>}
            component={RouterLink} to='/create_fable'
        />

        <BottomNavigationAction label="Discover" value='/'
            icon={<DiscoverIcon style={{height: '1.5rem', width: '1.5rem'}}/>}
            component={RouterLink} to='/'
        />

        <BottomNavigationAction label="About" value='/about'
            icon={<AboutIcon style={{height: '1.5rem', width: '1.5rem'}}/>}
            component={RouterLink} to='/about'
        />
      </BottomNavigation>
    );
}

/**
 * Custom wrapper for RouterLink
 * @param {{to: string, underline: string, about: string}} props 
 */
const RefLink = forwardRef((props, ref) => {
    return (
        <Link underline='hover' style={{color: 'inherit'}} {...props} component={RouterLink} ref={ref}/>
    );
});

function SelectAvatar(props){
    console.dir(AVATARS);
    console.log(`./${AVATARS[0]}`);
    return (
        <GridList cellHeight={160} cols={4}>
            {
                AVATARS.map( avatar => (
                    <GridListTile>
                        <Avatar avatarPath={`./${avatar}`} key={avatar}/>
                    </GridListTile>
                ))
            }
        </GridList>
    )
}

export {ThemedPaper, ThemedPaperLayout, ThemedTypography, ThemedMobileNav, ThemedLinksNav, RefLink, ColorTheme, SiteTitle, SiteDomain, SelectAvatar }