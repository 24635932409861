import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { ColorTheme } from '../../utils/ThemedComponents';
import { SignUpForm } from './SignUp'

export default class SignUpLoginDialogue extends React.Component {
    constructor(props) {
        super (props);
        this.state = {
            isOpen: false,
            isNew: this.props.isNew,
            setIsNew: this.setIsNew.bind(this),
            handleClose: this.handleClose.bind(this),
            toggleOpen: this.toggleOpen.bind(this),
        }
    }

    setIsNew(value){
        this.setState({isNew: value});
    }

    handleClose (event) {
        this.setState({isOpen: false});
    }

    toggleOpen (event) {
        this.setState({isOpen: !this.state.isOpen});
    }

    render () {
        return (
            <Dialog         
                fullWidth={true}
                maxWidth={'sm'}
                open={this.state.isOpen}
                onClose={this.state.handleClose}
                aria-labelledby="max-width-dialog-Login-Signup"
                PaperProps={{style: {backgroundColor: ColorTheme.dark}}}>
                <DialogContent>
                    <SignUpForm handleClose={this.state.handleClose} isNew={this.state.isNew}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.state.handleClose} style={{color: ColorTheme.bright}}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SignUpLoginDialogue.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
}