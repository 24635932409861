import React, { forwardRef } from "react";
import Icon from "@material-ui/core/Icon";
const { PropTypes } = require('prop-types');

/**
 * @param {{src: string, iconSize: string}} props Src Source of the any image file (SVG, PNG, etc.)
 * iconSize relative size for the icon ['large', xlarge'] defaults to 'medium'
 */
export const IconWrapper = forwardRef( (props, ref) => {
    IconWrapper.propTypes = {
        src: PropTypes.string.isRequired,
    }
    
    return (
        <Icon
            {...props}
            IMGSize={props.iconSize}
            //avatar property?
            src={props.src.default ? props.src.default : props.src}
            ref={ref}
            component={IMGWrapper}
        />
    )
});

/**
 * Different from SVGIconWrapper since
 * this knows the context of all Profile SVG's
 */
const IMGWrapper = forwardRef( (props, ref) => {
    IMGWrapper.propTypes = {
        src: PropTypes.string.isRequired,
    }
    var size = '2.5rem' //props.avatarSize === 'large' ? '4rem' : '2.5rem';
    switch (props.IMGSize) {
        case 'small':
            // For mobile view
            size='1.5rem';
            break;

        case 'medium':
            // For mobile view
            break;

        case 'large':
            // For desktop/mobile view
            size = '4rem';
            break;
    
        case 'xlarge':
            // For desktop view
            size = '5rem';
            break;

        default:
            break;
    }
    return (
        <img ref={ref} style={{ height: size, width: size, ...props.style, }} src={props.src}></img>
    )
});